<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <div class="search-content-holder">
            <div class="details_section">
              <div class="user_personal_info">
                <div class="content">
                  <div v-if="Object.keys(guardian).length" class="profile_head">
                    <img :src="guardian.profile_image" alt="" />
                    <h1>{{ guardian.full_name }}</h1>
                    <p style="text-align: center; margin-bottom: 0">
                      {{ guardian.taddress }}
                    </p>

                    <div class="progress-profile">
                      <h4>
                        PROFILE PROGRESS {{ guardian.profile_percentage }}%
                      </h4>
                      <v-progress-linear
                        :rounded="true"
                        :value="guardian.profile_percentage"
                      ></v-progress-linear>
                    </div>

                    <div class="special_info">
                      <div class="info">
                        <small>Joined</small>
                        <p style="font-size: 13px">
                          {{ guardian.join_date_format }}
                        </p>
                      </div>
                      <div class="info">
                        <small>Children</small>
                        <p>{{ children.length }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="other_personal_info">
                    <h4>Children</h4>

                    <v-list
                      two-line
                      v-for="(child, key) in children"
                      :key="key"
                      class="vlist"
                    >
                      <v-list-group no-action>
                        <template v-slot:activator>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-img
                                :src="
                                  child.profile_image
                                    ? child.profile_image
                                    : '-'
                                "
                              />
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                child.full_name
                              }}</v-list-item-title>
                              <v-list-item-subtitle
                                >{{
                                  child.academic ? child.academic.grade : "-"
                                }}
                                /
                                {{
                                  child.academic ? child.academic.section : "-"
                                }}
                                / Roll /
                                {{
                                  child.academic
                                    ? child.academic.enroll_code
                                    : "-"
                                }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </template>

                        <v-list two-line>
                          <v-list-item>
                            <v-list-item-action>
                              <!--<v-icon color="indigo">supervised_user_circle</v-icon>-->
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>DOB </v-list-item-title>
                              <v-list-item-subtitle
                                >{{ child.dob }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-action>
                              <!--<v-icon color="indigo">supervised_user_circle</v-icon>-->
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title> Gender </v-list-item-title>
                              <v-list-item-subtitle
                                >{{ child.gender.toUpperCase() }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-action>
                              <!--<v-icon color="indigo">supervised_user_circle</v-icon>-->
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                                >Class Teacher
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <span>
                                  {{
                                    child.academic
                                      ? child.academic.class_teacher
                                        ? child.academic.class_teacher.name
                                        : ""
                                      : ""
                                  }}
                                </span>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-list-group>
                    </v-list>
                  </div>
                </div>
              </div>
              <div class="user_detail_info">
                <v-card>
                  <v-tabs v-model="tab" background-color="primary" dark>
                    <v-tab key="One"> User Details </v-tab>
                    <v-tab key="Two"> Billings </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tab">
                    <v-tab-item key="One">
                      <v-card flat>
                        <v-card-text>
                          <div class="tab_box" style="padding: 0">
                            <v-container
                              style="padding: 0"
                              class="user-address user-profile-info"
                              grid-list-md
                            >
                              <v-card class="elevation-0">
                                <personal
                                  v-if="!updatePersonal"
                                  :user="guardian"
                                />
                              </v-card>
                            </v-container>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item key="Two">
                      <v-card flat>
                        <v-card-text>
                          <div class="tab_box">
                            <div class="search-box">
                              <v-layout row wrap>
                                <v-flex xs12>
                                  <v-autocomplete
                                    v-model="selectedChild"
                                    :items="selectChildrenList"
                                    hide-no-data
                                    @change="selectedChildren"
                                    outlined
                                    dense
                                    label="Start typing child name"
                                    color="blue-grey"
                                    placeholder="Click to select"
                                    item-text="name"
                                    item-value="name"
                                    return-object
                                  >
                                    <template v-slot:selection="data">
                                      <v-chip
                                        color="#1976d2"
                                        :selected="data.selected"
                                        close
                                        class="chip--select-multi"
                                        @input="remove(data.item)"
                                      >
                                        <v-avatar>
                                          <img :src="data.item.avatar" />
                                        </v-avatar>
                                        <span style="color: white">{{
                                          data.item.name
                                        }}</span>
                                      </v-chip>
                                    </template>
                                    <template
                                      @click="selectedChildren(data)"
                                      v-slot:item="data"
                                    >
                                      <template
                                        v-if="typeof data.item !== 'object'"
                                      >
                                        <v-list-item-content
                                          v-text="data.item"
                                        ></v-list-item-content>
                                      </template>
                                      <template v-else>
                                        <v-list-item-avatar>
                                          <img :src="data.item.avatar" />
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title
                                            v-html="data.item.name"
                                          ></v-list-item-title>
                                          <v-list-item-subtitle
                                            v-html="data.item.group"
                                          ></v-list-item-subtitle>
                                        </v-list-item-content>
                                      </template>
                                    </template>
                                  </v-autocomplete>
                                </v-flex>
                              </v-layout>
                            </div>

                            <!--<v-divider></v-divider>-->

                            <alert-message
                              v-if="Object.keys(selectedChild).length < 1"
                              type="warning"
                              title="No child selected!!"
                              message="Select a child from the in order to view the billing records!!"
                            />
                            <div class="billing_content" v-else>
                              <ul class="billing_lagend">
                                <li class="bill_amount">
                                  Bill Amount ({{ billSum.currency() }})
                                </li>
                                <li class="paid_amount">
                                  Paid Amount ({{ paidSum.currency() }})
                                </li>
                              </ul>
                              <p
                                style="
                                  padding-left: 25px;
                                  margin-top: 0;
                                  font-size: 13px;
                                  color: #777;
                                  line-height: 1;
                                "
                              >
                                Month wise annual turnover report
                              </p>

                              <apexchart
                                type="area"
                                height="200"
                                :options="chartOptions"
                                :series="series"
                              ></apexchart>

                              <v-divider></v-divider>

                              <br />
                              <div class="adv_due_rep">
                                <div class="report_box_content">
                                  <div class="report_box advance_rep">
                                    <v-icon>trending_up</v-icon>
                                    <h2>{{ advanceAmount.currency("") }}</h2>
                                    <p>Total Advance</p>
                                    <div class="line"></div>
                                  </div>
                                  <div class="report_box due_rep">
                                    <v-icon>trending_down</v-icon>
                                    <h2>{{ dueAmount.currency("") }}</h2>
                                    <p>Total Due</p>
                                    <div class="line"></div>
                                  </div>
                                </div>
                                <div
                                  style="
                                    display: flex;
                                    justify-content: space-between;
                                  "
                                >
                                  <div>
                                    <p>Advance & Due Report</p>
                                    <h4>
                                      On Statements till
                                      {{
                                        Object.keys(lastStatementItem).length
                                          ? lastStatementItem.bill_date
                                          : "-"
                                      }}
                                    </h4>
                                  </div>
                                  <div>
                                    <div
                                      v-if="!isDue"
                                      class="icon-alert on-advance"
                                    >
                                      <v-icon>trending_down</v-icon>
                                    </div>

                                    <div v-else class="icon-alert on-due">
                                      <v-icon>trending_up</v-icon>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>

                <div class="guardian-graph">
                  <guardian></guardian>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Personal from "./../../Profile/modules/personal";
import guardian from "./../../../../../components/svg/guardian";
import AlertMessage from "./../../../../../components/AlertMessage";

export default {
  components: {
    Personal,
    guardian,
    AlertMessage,
  },
  computed: {
    isDue() {
      return this.billSum > this.paidSum;
    },
    advanceAmount() {
      if (this.isDue) {
        return 0;
      }
      return this.paidSum - this.billSum;
    },
    dueAmount() {
      if (this.isDue) {
        return this.billSum - this.paidSum;
      }
      return 0;
    },
  },
  data() {
    return {
      tab: null,
      children: [],
      guardian: {},
      updatePersonal: false,
      // selectedChild: false,
      selectedChild: {},
      selectChildrenList: [],
      series: [
        {
          name: "BILLING",
          data: [],
        },
        {
          name: "PAID",
          data: [],
        },
      ],
      chartOptions: {},
      statementList: [],
      headers: [
        { text: "S. No", align: "left", value: "id", sortable: false },
        { text: "Date", align: "left", value: "bill_date", sortable: false },
        { text: "Via", align: "left", value: "via", sortable: false },
        {
          text: "Bill Amount",
          align: "left",
          value: "bill_amount",
          sortable: false,
          width: 140,
        },
        {
          text: "Paid Amount",
          align: "left",
          value: "paid_amount",
          sortable: false,
          width: 140,
        },
        {
          text: "Instant Discount",
          align: "left",
          value: "instant_discount",
          sortable: false,
          width: 140,
        },
        {
          text: "Remarks",
          align: "left",
          value: "remarks",
          sortable: false,
          width: 200,
        },
      ],
      loading: false,
      billSum: 0,
      instantDiscountSum: 0,
      paidSum: 0,
      lastStatementItem: {},
    };
  },
  mounted() {
    this.fetchGuardian();
    this.fetchChildren();
  },
  methods: {
    fetchChildren() {
      const { id } = this.$route.params;
      this.$rest.get("/api/guardian-child/" + id).then(({ data }) => {
        this.children = data.data;
        let $this = this;
        $this.selectChildrenList = [];
        this.children.map(function (item) {
          if (item.academic)
            $this.selectChildrenList.push({
              id: item.id,
              enroll_id: item.academic.enroll_id,
              enroll_code: item.academic.enroll_code,
              name: item.full_name,
              avatar: item.profile_image,
              group:
                item.academic.grade +
                "/" +
                item.academic.section +
                "/" +
                item.academic.enroll_code,
            });
        });
      });
    },
    fetchGuardian() {
      const { id } = this.$route.params;
      this.$rest.get("/api/user/" + id + "/details").then(({ data }) => {
        this.guardian = data;
      });
    },
    selectedChildren(child) {
      this.selectedChild = child;
      if (child.enroll_code.length < 1) return;
      let billDates = [];
      this.loading = true;
      this.$rest
        .get("/api/report/statement?enrollCode=" + child.enroll_code)
        .then(({ data }) => {
          let billingRecords = {
            paid: {},
            bill: {},
          };
          this.series[1].data = [];
          this.series[0].data = [];
          let $this = this;
          data.data.map(function (item, index) {
            if (!billDates.includes(item.bill_date.substr(5))) {
              billDates.push(item.bill_date.substr(5));

              if (item.bill_amount !== 0)
                billingRecords.bill[item.bill_date.substr(5)] =
                  item.bill_amount;
              else if (item.paid_amount !== 0)
                billingRecords.paid[item.bill_date.substr(5)] =
                  item.paid_amount;
            } else {
              if (item.bill_amount !== 0) {
                if (!billingRecords.bill[item.bill_date.substr(5)])
                  billingRecords.bill[item.bill_date.substr(5)] = 0;

                billingRecords.bill[item.bill_date.substr(5)] +=
                  item.bill_amount;
              } else if (item.paid_amount !== 0) {
                if (!billingRecords.paid[item.bill_date.substr(5)])
                  billingRecords.paid[item.bill_date.substr(5)] = 0;

                billingRecords.paid[item.bill_date.substr(5)] +=
                  item.paid_amount;
              }
            }

            if (index + 1 === data.data.length) {
              $this.lastStatementItem = item;
            }
          });

          billDates.map(function (item) {
            if (billingRecords.paid[item]) {
              $this.series[1].data.push(billingRecords.paid[item]);
            } else {
              $this.series[1].data.push(0);
            }

            if (billingRecords.bill[item]) {
              $this.series[0].data.push(billingRecords.bill[item]);
            } else {
              $this.series[0].data.push(0);
            }
          });

          this.chartOptions = {
            colors: ["#6387a3", "#4fc2d8"],
            legend: {
              position: "top",
              show: false,
            },
            grid: {
              show: false,
            },
            chart: {
              height: 200,
              type: "area",
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
            },
            xaxis: {
              lines: {
                show: true,
              },
              categories: billDates,
            },
            yaxis: {
              lines: {
                show: false,
              },
              show: false,
            },
            tooltip: {
              x: {
                format: "dd/MM/yy HH:mm",
              },
            },
          };

          //Prepare data for the table.
          $this.billSum = 0;
          $this.instantDiscountSum = 0;
          $this.paidSum = 0;
          data.data.map((item) => {
            $this.billSum += parseFloat(item.bill_amount);
            $this.paidSum += parseFloat(item.paid_amount);
            $this.instantDiscountSum += parseFloat(item.instant_discount);
          });
          this.statementList = data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    remove(item) {
      this.selectedChild = {};
    },
  },
};
</script>

<style lang="scss">
/*.v-main__wrap{*/
/*flex: 1 1 auto;*/
/*}*/

.adv_due_rep {
  max-width: 270px;
  .report_box_content {
    display: flex;
    .report_box {
      margin-right: 10px;
      border-radius: 20px;
      min-width: 130px;
      min-height: 150px;
      padding: 13px;
      color: white;
      div.line {
        width: 40px;
        height: 5px;
        border-radius: 10px;
        background: white;
      }
      i {
        color: white;
        font-size: 30px;
      }
      h2 {
        margin-top: 15px;
        + p {
          font-size: 12px;
          margin-top: 10px;
          color: white;
        }
      }
      &.advance_rep {
        background: #6387a3;
      }
      &.due_rep {
        background: #4fc2d8;
      }
    }
  }
  p {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 12px;
    color: #888;
    + h4 {
      color: #555;
      font-weight: 500;
      line-height: 1;
    }
  }
  .icon-alert {
    margin-top: 10px;
    height: 24px;
    width: 24px;
    text-align: center;
    border-radius: 50%;
    padding: 3px;
    i {
      font-size: 18px;
    }
    &.on-advance {
      background: rgba(99, 135, 163, 0.3);
      i {
        color: rgba(99, 135, 163, 1) !important;
      }
    }
    &.on-due {
      i {
        color: #4fc2d8 !important;
      }
      background: rgba(79, 194, 216, 0.3);
    }
  }
}

.billing_lagend {
  display: flex;
  padding: 0 0 0 10px;
  li {
    list-style: none;
    margin-right: 20px;
    font-weight: bold;
    color: #555;
    &:before {
      content: "";
      display: inline-block;
      margin-right: 5px;
      height: 10px;
      width: 10px;
    }
    &.bill_amount {
      &:before {
        background: #6387a3;
        /*"#6387a3", "#4fc2d8"*/
      }
    }

    &.paid_amount {
      &:before {
        background: #4fc2d8;
      }
    }
  }
}

.light_text {
  color: #999;
}

.no-info-alert {
  /*background: red;*/
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  h1 {
    color: #666;
    + p {
      color: #999;
    }
  }

  align-items: center;
  .search-result {
  }
}

.search-content-holder {
  min-height: 450px;
  display: flex;
  .user_personal_info {
    .v-timeline {
      padding-top: 0;
    }
    flex: 4;
    overflow: hidden;
    padding: 20px 0;
    /*background-color: green;*/
    .content {
      height: 100%;
      flex: 1;
      border-right: 1px solid #e3e3e3;
      padding: 0 15px;
      .profile_head {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px dashed #e0e0e0;
        display: flex;
        align-items: center;
        flex-direction: column;
        color: #666;
        font-size: 13px;
        img {
          border: 5px solid #eee;
          height: 120px;
          width: 120px;
          border-radius: 150px;
        }
        h1 {
          font-size: 23px;
          margin-top: 15px;
          font-weight: normal;
          line-height: 1;
        }
        p {
          color: #888;
          line-height: 1.5;
        }
        .special_info {
          display: flex;
          width: 97%;
          margin-top: 15px;
          .info {
            background-color: transparent !important;
            background: none;
            width: 50%;
            border-right: 3px solid #eee !important;
            padding-left: 10px;
            padding-right: 10px;
            margin-right: 10px;
            display: block;
            &:last-child {
              border-right: none !important;
            }
            small {
              font-size: 10px;
              color: #ccc;
              font-weight: bold;
            }
            p {
              font-weight: bold;
              color: #777;
              font-size: 16px;
              margin-top: 0;
              line-height: 1;
              margin-bottom: 0;
            }
          }
        }
        .progress-profile {
          h4 {
            font-size: 12px;
            margin-bottom: 0;
            line-height: 1.5;
          }
          .v-progress-linear {
            margin-top: 5px;
            height: 5px !important;
          }
          margin-top: 15px;
          width: 90%;
        }
      }
      .other_personal_info {
        .v-list__group__header__prepend-icon {
          padding: 0;
        }
        .v-list__group__header__prepend-icon {
          .v-icon {
            color: #1976d2 !important;
          }
        }
        /*padding: 0 15px;*/
        color: #777;
        h4 {
          color: #999;
        }
        .v-list__tile {
          padding: 0 !important;
        }
      }
    }
  }
  .user_detail_info {
    margin-top: 5px;
    position: relative;
    .guardian-graph {
      opacity: 0.2;
      right: -100px;
      position: absolute;
      bottom: 20px;
      width: 400px;
      height: 400px;
      display: flex;
    }
    .v-timeline {
      padding-top: 0 !important;
    }
    .input-label {
      color: #888;
    }
    flex: 6.5;
    padding: 0 20px;
    .caption {
      color: #777;
    }
    .v-tabs {
      a {
        color: #333;
      }
      .theme--dark.v-icon {
        color: #333;
      }
    }

    .v-tabs__container--icons-and-text .v-tabs__item {
      flex-direction: row;
    }
    .v-tabs__item {
      justify-content: center;
    }
    .v-tabs__container {
      border-bottom: 1px solid #ccc;
    }
    .v-tabs__item {
      padding: 6px 0;
    }
    .v-tabs__container--grow .v-tabs__div {
      flex: none;
    }
    .v-tabs__container--icons-and-text {
      height: 50px;
      background-color: #f6f6f6;
      border-radius: 6px;
      overflow: hidden;
    }

    /*background-color: orange;*/
  }

  .tab_box {
    /*background-color: red;*/
    padding: 15px;
    margin-top: 5px;
    .tab_title {
      font-weight: bold;
      color: #ccc;
      p {
        margin-bottom: 0;
      }
    }

    .batch_lists_container {
      .batch_list {
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
        padding: 5px 10px;
        border-radius: 2px;
        margin-bottom: 20px;
        margin-top: 10px;
        &.active {
          border: 1px solid #b4d3f1;
          box-shadow: 0 3px 15px 2px rgba(0, 0, 0, 0.1);
        }
        p {
          margin-bottom: 0;
          line-height: 1.3;
        }
        h3 {
          margin-top: 0;
          color: #666;
          border-bottom: 1px dashed #eee;
          margin-bottom: 10px;
          padding-bottom: 10px;
          i {
            color: #1976d2 !important;
            font-size: 18px;
          }
        }
        .academic_record {
          margin-top: 5px;
          /*border-left: 2px solid #999;*/
          padding: 2px 0;
          font-size: 13px;
          margin-bottom: 8px;
          color: #999;
          font-weight: bold;
          margin-left: -7px;
          line-height: 1;
          i {
            color: #999;
            font-size: 16px;
            line-height: 0.9;
          }
          span {
            float: right;
            font-weight: normal;
            font-size: 12px;
            /*text-align: right;*/
          }
        }
      }
    }

    .academic_report {
      display: flex;
      margin-top: 15px;
      .report_box {
        cursor: pointer;
        min-height: 80px;
        background-color: white;
        border-right: 2px;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        flex: 1;
        display: flex;
        align-items: center;
        &.active {
          border-bottom: 5px solid #b4d3f1;
        }
        .chart_box {
          height: 50px;
          margin: 0 10px 0 8px;
          width: 50px;
          border-radius: 50%;
        }
        .chart_info {
          /*background-color: red;*/
          height: 30px;
          h4 {
            margin-bottom: 0;
            color: #666;
            line-height: 1;
          }
          p {
            font-size: 12px;
            color: #999;
            margin-top: 0;
            /*font-weight: bold;*/
          }
        }
      }
      .assignment_rep {
        margin-right: 10px;
        .chart_box {
          background-color: #724a9f;
        }
      }

      .attendance_rep {
        margin-right: 10px;
        .chart_box {
          background-color: #1976d2 !important;
        }
      }

      .subject_rep {
        margin-right: 10px;
        .chart_box {
          background-color: #ff994f !important;
        }
      }

      .subject_rep {
        flex: 1;
      }
    }
  }
}

.hide_search {
  height: 0 !important;
}

.details_section {
  display: flex;
  flex: 1;
}

.page-layout {
  border-top: 10px solid #ccc;
  .v-card__title {
    font-weight: normal;
    color: #333;
    font-size: 18px !important;
    .head_icon.v-icon {
      font-size: 18px;
      background-color: #ccc;
      color: #333;
      border-radius: 20px;
      padding: 3px;
    }
  }
}

.aio {
  .v-btn:not(.v-btn--depressed):not(.v-btn--flat) {
    box-shadow: none;
  }
  .theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
    background-color: transparent;
  }
  .v-btn--large {
    font-weight: bold;
    font-size: 14px;
    color: #888;
    padding: 0;
  }
  .search-content-holder {
    padding: 10px 20px;
    min-height: 750px;
  }

  .v-list__tile__action,
  .v-list__tile__avatar {
    min-width: 35px;
  }
  .v-list__tile__sub-title,
  .v-list__tile__title {
    white-space: normal;
  }
}

.child-tab {
  padding: 0;
  display: flex;
  align-items: flex-end;
  ul {
    padding: 0;
    margin-left: auto;
  }
  li {
    list-style: none;
    a {
      text-decoration: none;
      background-color: #e3effb;
      color: #666 !important;
      margin-left: 3px;
      padding: 8px 15px;
      &.active {
        border-bottom: 3px solid #1976d2;
        color: #1976d2 !important;
        font-weight: bold;
      }
    }
    display: inline-block;
  }
}
.v-tab.v-tab--active {
  color: white !important;
}
</style>
